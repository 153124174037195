import React from 'react';
import NextLink from 'next/link';

const CopyrightTerms = () => {
  return (
    <div className="mt-6 flex justify-center text-gray-400">
      &copy; RipeMetrics {new Date().getFullYear()}. All rights reserved.
      <NextLink
        href="https://ripemetrics.com/legal/privacy-policy"
        passHref
        className="px-1 font-medium text-zinc-600 hover:text-orange-500 hover:underline"
        target="_blank"
      >
        Privacy
      </NextLink>
      and
      <NextLink
        href="https://ripemetrics.com/legal/terms-of-service"
        passHref
        className="px-1 font-medium text-zinc-600 hover:text-orange-500 hover:underline"
        target="_blank"
      >
        Terms
      </NextLink>
      .
    </div>
  );
};

export default CopyrightTerms;
