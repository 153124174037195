import { useAuth } from '@/lib/hooks/use-auth';
import { useMounted } from '@/lib/hooks/use-mounted';
import { RootState } from '@/lib/state/store';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import { useFormik } from 'formik';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

type User = {
  permissions: {
    entrance_url: string;
  };
};

export const RtkLogin: FC<React.PropsWithChildren<unknown>> = (props) => {
  // Access the auth state
  const user = useSelector((state: RootState) => state.auth.user);
  const isMounted = useMounted();
  const router = useRouter();
  const { login } = useAuth();
  const [errorMesaage, setErrorMessage] = useState<string | null>(null);
  const { disableGuard } = router.query;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required')
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        const loginFormData = {
          email: values.email,
          password: values.password
        };
        const response = await login(loginFormData);
        console.log('response', response);
        if (response.data.error) {
          setErrorMessage(response.data.error);
        }

        // wait with console.log until the state is updated

        // console.log('state AUTH in RTK-LOGIN', state);

        // if (isMounted()) {
        //   const returnUrl = state?.auth?.user.permissions.entrance_url;
        //   console.log('returnUrl', returnUrl);
        //   router.push(returnUrl).catch(console.error);
        // }
      } catch (err) {
        console.error(err.response.data);

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.response.data.error });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <TextField
        autoFocus
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Email Address"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        value={formik.values.email}
      />
      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
      />
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>
            {formik.errors.submit
              ? formik.errors.submit
              : 'An error occurred during login'}
          </FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <div className="my-3 flex items-center justify-between">
          <NextLink
            href={
              disableGuard
                ? `/authentication/password-recovery?disableGuard=${disableGuard}`
                : '/authentication/password-recovery'
            }
            passHref
          >
            <div className="ml-auto text-sm">
              <span className="text-base font-medium text-orange-500 hover:text-orange-600">
                Forgot your password?
              </span>
            </div>
          </NextLink>
          <NextLink
            href={'/authentication/register'}
            passHref
          >
            <div className="ml-auto text-sm">
              <span className="text-base font-medium text-orange-500 hover:text-orange-600">
                Create Account
              </span>
            </div>
          </NextLink>
        </div>
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Sign In
        </Button>
      </Box>
    </form>
  );
};
